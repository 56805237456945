import styled from 'styled-components'
import * as palette from '../styles/variables';
import { device } from '../styles/device';


export const ContactAddressStyled = styled.div`
    background-color: ${palette.darkBlueGrey};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media ${device.tablet} {
        flex-direction: row;
    }

    .map {
        width: 100%;
        height: auto;
        margin-bottom: 0;
        min-height: 300px;

        @media ${device.tablet} {
            width: 50%;
        }
    }

    .addressSection {
        padding: 5rem 15px 5rem 15px;
        flex-basis: 50%;
        color: white;

        .titleContactWrap {
            text-align: center;
            width: 100%;
            margin: 0 auto;

            @media ${device.tablet} {
                width: 80%;
            }

            .underLine {
                width: 50%;
                border-bottom: 5px solid ${palette.white};
                margin: 0 auto;
            }
        }

        .brandLogo {
            width: 90px;
            display: block;
            margin: 0 auto;
        }

        .contactWrap {
            width: 80%;
            margin: 0 auto;
            padding-top: 3rem;

            .detailWrap {
                margin-top: 2rem;

                svg {
                    border: 1px solid ${palette.textDarkGrey};
                    box-sizing: content-box;
                    padding: 0.7rem;
                }

                .emailWrap, .addressWrap {
                    font-size: 30px;
                    width: 100%;
                    text-align: center;
                }

                .emailWrap {
                    margin-bottom: 2rem;
                }

                .email, .address {
                    font-size: ${palette.fontSizeMd};
                    margin-top: 1rem;
                }
            }

        }

    }


`;

