import React, { Component } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import Hero from '../components/hero'
import ContactAddress from '../components/contactAddress'


class Contact extends Component {
    render() {
        if(!this.props.data.allContentfulTitle.edges) {
            return <div>Loading...</div>
        }
        const descriptionMeta = this.props.data.allContentfulTitle.edges[0].node.text
        return (
            <Layout>
            <SEO title="Contact" description={descriptionMeta} />
            <Hero position="Contact"
                    buttonText="Contact us"
                    classButton="contactCta"
                    bannerImage={true}
                    noBackgroundHero={false}
                    className="contactHero">
            </Hero>
            <ContactAddress />
        </Layout>
        )
    }
}

export default Contact

export const pageQuery = graphql`
    query ContactPageQuery {
        allContentfulTitle(filter: {position: {eq: "Contact"}}) {
            edges {
                node {
                    text
                }
            }
        }
    }
`